@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Ranade';
  src: url('/public/assets/Ranade-Variable.ttf') format('ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

*,
body {
  margin: 0;
  font-family: 'Ranade', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background-color: hsl(231, 16%, 92%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root,
body,
html {
  /* Prevent overscroll on chrome (not supported on safari) */
  overscroll-behavior-y: none;
}
.h-fill {
  height: 100vh;
  max-height: -webkit-fill-available;
}

.font-righteous {
  font-family: 'Righteous', cursive;
}
